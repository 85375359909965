<template>
    <div>
    <el-container>
        <el-header height="100%">
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
          <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>INNOVATION</p>
               <span>创新驱动</span>
             </div>
           </div>
        </el-header>
        <el-main class="inac">
        <el-row type="flex" justify="center">
          <el-col :sm="24" :md="20" :lg="20">
                    <div class="w wb inac-mic">
                      <div class="ec-top-foot">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                          <el-breadcrumb-item > <router-link to="/">首页</router-link></el-breadcrumb-item>
                          <el-breadcrumb-item>创新驱动</el-breadcrumb-item>
                        </el-breadcrumb>
                      </div>
                      <div class="ade">
                        <img class="hidden-sm-and-down" src="../../assets/images/en/A/A3z.jpg" alt="">
                        <img class="hidden-md-and-up" src="../../assets/images/en/A/A3z-i.jpg" alt="">
                      </div>
                        <a name="gailan"></a>
                        <div v-for="item in midea" :key="item">
                          <!-- <video autoplay muted loop  width="100%" :src="item" alt="" poster="../../assets/images/common/loading.gif"></video> -->
                          <video class="hidden-sm-and-down" width="100%"  autoplay muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                          <video class="hidden-md-and-up" width="100%" controls preload muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                        </div>
                        <div class="A-item-txt" v-html="msg"></div>
                        <div class="A-item-s">
                            <div class="A-item" v-for="(item, index) in Scityb" :key="index">
                            <el-row class="A-itema">
                                <el-col   :md="7" :lg="7" :xl="7" class="item-a hidden-sm-and-down">
                                    <div class="hidden-sm-and-down item-ss">
                                        <div class="item-b">0{{index+1}}</div>
                                            <div class="item-a-c">
                                                <div class="item-c" v-html="item.ONE"></div>
                                                <div class="item-down" v-html="item.TWO"></div>
                                            </div>
                                    </div>
                                </el-col >
                                <el-col :xs="24" :sm="24" class="item-a-up hidden-md-and-up">
                                  <div class="item-b-txt-up">
                                    <div class="item-b-up">0{{index+1}}</div>
                                    <div class="item-a-c-up">
                                        <div class="item-c-up" v-html="item.ONE"></div>
                                        <div class="item-up" v-html="item.TWO"></div>
                                    </div>
                                  </div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17" class="item-t">
                                    <p class="item-txt hidden-sm-and-down">
                                        {{item.SHORTCONTENT}}
                                    </p>
                                    <p class="item-txt-up hidden-md-and-up">
                                        {{item.SHORTCONTENT}}
                                    </p>
                                </el-col>
                            </el-row>
                        </div>
                        </div>
                        <a name="gaige"></a>
                        <div class="bg-b">
                            <img width="100%" :src="ims" alt="">
                        </div>
                        <div class="A-item" v-for="(item, index) in Scity" :key="index">
                            <el-row class="A-itema">
                                <el-col :md="7" :lg="7" :xl="7" class="item-a item-a-bl hidden-sm-and-down">
                                    <div class="item-b-c-up item-ss hidden-sm-and-down">
                                        <div class="item-b"> <p>0{{index+4}}</p></div>
                                        <div class="item-a-c">
                                            <div class="item-a-c-a">
                                                <div class="item-c" v-html="item.ONE"></div>
                                                <div class="item-down" v-html="item.TWO"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-w">{{ item.URL }}</div>
                                </el-col >
                                <el-col :xs="24" :sm="24" class="item-a-up item-a-bl hidden-md-and-up">
                                    <div class="item-b-c-up hidden-md-and-up">
                                      <div class="item-b-txt-up">
                                        <div class="item-b-up">0{{index+4}}</div>
                                        <div class="item-a-c-up">
                                          <div class="item-a-c-txt">
                                            <div class="item-c-up" v-html="item.ONE"></div>
                                            <div class="item-up" v-html="item.TWO"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <img class="item-fx-up" src="../../assets/images/A/A3fx.png" alt="">
                                    </div>
                                    <div class="item-w-up">{{ item.URL }}</div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="17" :lg="17" :xl="17" class="item-t">
                                    <div class=" hidden-sm-and-down">
                                        <p class="item-txt">
                                            {{ item.SHORTCONTENT }}
                                        </p>
                                        <img class="item-fx" src="../../assets/images/A/A3fx.png" alt="">
                                    </div>
                                    <div class="hidden-md-and-up">
                                        <p class="item-txt-up">
                                            {{ item.SHORTCONTENT }}
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <gotop></gotop>
                    </div>
                    <div class="sin">
                        <el-popover
                        placement="right"
                        title="创新城市"
                        width="150"
                        height='20'
                        trigger="hover">
                        <a href="#gailan" slot="reference"></a>
                        </el-popover>
                        <el-popover
                            placement="left"
                            title="创新机构"
                            width="150"
                            trigger="hover">
                            <a slot="reference" href="#gaige"></a>
                        </el-popover>
                    </div>
                  </el-col>
                </el-row>
            </el-main>
        <el-footer>
          <vfooter class="hidden-sm-and-down"></vfooter>
          <ivfooter class="hidden-md-and-up"></ivfooter>
        </el-footer>
     </el-container>
    </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { szcityc, szcitycb } from '../../api'

export default {
  data () {
    return {
      btnFlag: false,
      Scity: {},
      Sctiyb: {},
      msg: {},
      midea: {},
      ims: {}
    }
  },
  mounted () {
    szcityc().then(res => {
      // console.log(res.data)
      this.msg = res.data[0].ONE
      this.midea = res.data[0].THREE
      this.ims = res.data[0].TWO
      let arr = this.midea.split('</p>')
      // eslint-disable-next-line no-array-constructor
      let src = new Array()
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].indexOf('src') !== -1) {
          src.push(arr[i].match(/src="(\S*)"/)[1])
        }
      }
      // console.log(3)
      // console.log(src)
      this.midea = src
      // console.log(this.midea)
      //   console.log(this.msg)
      let arrs = this.ims.split('</p>')
      // eslint-disable-next-line no-array-constructor
      let srcs = new Array()
      for (let i = 0; i < arrs.length; i++) {
        if (arr[i].indexOf('src') !== -1) {
          srcs.push(arrs[i].match(/src="(\S*)"/)[1])
        }
      }
      // console.log(3)
      // console.log(src)
      this.ims = srcs
      // console.log(this.ims)
    })
    szcitycb().then(res => {
      console.log(2)
      this.Scity = res.data
      this.Scityb = this.Scity.splice(0, 3)
      console.log(this.Scity)
      console.log(this.Scityb)
    })
  },
  components: {
    Gotop
  }
}
</script>

<style src='../../assets/css/CityA3.css' ></style>

<style>
.item-ss .item-a-c .item-c {
  white-space: inherit;
}
</style>
